import { motion } from "framer-motion";
import IconChevronUp from "./icons/IconChevronUp";

const Level = ({
  onclick,
  icon,
  choseLevel,
  checked,
  isOpen,
  onClickRules,
}) => {
  return (
    <div className="level-btn">
      <div className="level-btn-header">
        <label className="radio-btn-container" onClick={onclick}>
          <input type="radio" name="radio" checked={checked} readOnly />
          {icon}
          <span
            className={`level-btn-level ${
              checked === "checked" && "level-btn--checked"
            }`}
          >
            {choseLevel}
          </span>
          <span className="checkmark"></span>
        </label>
        <div className="level-rules" onClick={onClickRules}>
          <p className="level-rules-headline">Spilleregler</p>
          <motion.div
            animate={isOpen ? { scaleY: -1 } : { scaleY: 1 }}
            transition={{ duration: 0.2 }}
          >
            <IconChevronUp />
          </motion.div>
        </div>
      </div>
      <div className={isOpen ? "level-rules-container" : ""}></div>
      {isOpen && (
        <motion.div
          className="level-rules-text"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1 }}
        >
          {choseLevel === "Begynder" ? (
            <div className="level-rules-text-content">
              <p>
                Klik på en brik og derefter den brik, du gerne vil have den skal
                bytte plads med. Når alle brikker sidder som de skal er spillet
                vundet.
              </p>
              <p>
                Når brikken sidder på den rigtige plads, får den en grøn kant.
                Du kan se hele billedet ved at trykke på knappen i midten, og du
                kan skifte tema ved at klikke på piletasterne.
              </p>
            </div>
          ) : (
            <div className="level-rules-text-content">
              <p>
                Klik på en af de omkringliggende brikker for at "skubbe" dem hen
                på den tomme plads. Når alle brikker sidder, som de skal, er
                spillet vundet.
              </p>
              <p>
                Når brikken sidder på den rigtige plads, får den en grøn kant.
                Du kan se hele billedet ved at trykke på knappen i midten, og du
                kan skifte tema ved at klikke på piletasterne.
              </p>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export default Level;
