import Navigation from "../components/Navigation";
import Scores from "../components/Scores";

const Scoreboard = () => {
  return (
    <div className="background">
      <h3 className="page-headline">Topscores</h3>
      <Scores />
      <Navigation />
    </div>
  );
};

export default Scoreboard;
