import { useContext, useState } from "react";
import { LevelContext } from "../contexts/LevelContext";
import { SuccesContext } from "../contexts/SuccesContext";
import Navigation from "../components/Navigation";
import Level from "../components/Level";
import IconRocket from "../components/icons/IconRocket";
import IconParagliding from "../components/icons/IconParagliding";

const ChooseLevel = () => {
  const { level, setLevel } = useContext(LevelContext);
  const { setNumberOfMoves } = useContext(SuccesContext);
  const [isHardOpen, setIsHardOpen] = useState(false);
  const [isEasyOpen, setIsEasyOpen] = useState(false);

  // useEffect(() => {
  //   isHardOpen ? setIsEasyOpen(!isHardOpen) : setIsEasyOpen(isEasyOpen);
  //   isEasyOpen ? setIsHardOpen(!isEasyOpen) : setIsHardOpen(isHardOpen);
  // }, [isEasyOpen, isHardOpen]);

  return (
    <>
      <div className="background">
        <h3 className="page-headline">Vælg niveau</h3>
        <div className="level">
          <Level
            onclick={() => {
              setLevel("hard");
              setNumberOfMoves(0);
            }}
            choseLevel="Ekspert"
            icon={<IconRocket fill={level === "hard" && "#FFF"} />}
            checked={level === "hard" && "checked"}
            isOpen={isHardOpen}
            onClickRules={() => setIsHardOpen(!isHardOpen)}
            //onClickRules={(event) => handleShowRules(event, "hard")}
          />
          <Level
            onclick={() => {
              setLevel("easy");
              setNumberOfMoves(0);
            }}
            choseLevel="Begynder"
            icon={<IconParagliding fill={level === "easy" && "#FFF"} />}
            checked={level === "easy" && "checked"}
            isOpen={isEasyOpen}
            onClickRules={() => setIsEasyOpen(!isEasyOpen)}
          />
        </div>
      </div>
      <Navigation />
    </>
  );
};

export default ChooseLevel;
