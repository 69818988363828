export default function IconPuzzleFill({ height, width, classname }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="icon"
      className={classname}
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 12 12"
    >
      <g id="Group_30" data-name="Group 30" transform="translate(-164 -533)">
        <g
          id="Rectangle_73"
          data-name="Rectangle 73"
          transform="translate(164 533)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
        <g
          id="Rectangle_78"
          data-name="Rectangle 78"
          transform="translate(164 537)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
        <g
          id="Rectangle_80"
          data-name="Rectangle 80"
          transform="translate(164 541)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
        <g
          id="Rectangle_74"
          data-name="Rectangle 74"
          transform="translate(168 533)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
        <g
          id="Rectangle_77"
          data-name="Rectangle 77"
          transform="translate(168 537)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
        <g
          id="Rectangle_79"
          data-name="Rectangle 79"
          transform="translate(168 541)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
        <g
          id="Rectangle_75"
          data-name="Rectangle 75"
          transform="translate(172 533)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
        <g
          id="Rectangle_76"
          data-name="Rectangle 76"
          transform="translate(172 537)"
          fill="#00fe78"
          stroke="#00fe78"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="3" height="3" stroke="none" />
          <rect x="0.5" y="0.5" width="2" height="2" fill="none" />
        </g>
      </g>
    </svg>
  );
}
