import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SplashContext } from "../contexts/SplashContext";
import { AnimatePresence, motion } from "framer-motion";

const SplashTwo = () => {
  const { isFirstTime, setIsFirstTime } = useContext(SplashContext);
  let navigate = useNavigate();

  return (
    <div className="splash-background splash-two-background">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="splash-two-container"
      >
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 0.2, 0, 0.2, 0, 0.4, 1] }}
          transition={{ delay: 0.1, duration: 0.8 }}
          className="headline"
        >
          Game of maps
        </motion.h1>
        <div className="container">
          <p className="text-regular">Velkommen til Septimas puslespil!</p>
          <p className="text-regular">
            Flyt brikkerne rundt ved at klikke på én af de brikker, der grænser
            op til den tomme brik. Når alle brikkerne sidder som de skal, er
            spillet vundet.
          </p>
          <p className="text-regular">
            Du kan også skifte kort-tema på spillet, uden at du mister dit spil.
            Parat?
          </p>
        </div>
        <AnimatePresence>
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, scale: [1, 1.1, 1] }}
            transition={{
              delay: 1,
              duration: 0.3,
              type: "spring",
              damping: 50,
              stiffness: 700,
            }}
            exit={{ scale: 0.9, y: 10 }}
            className="main-btn"
            onClick={() => {
              setTimeout(setIsFirstTime(!isFirstTime), "2000");
              setTimeout(navigate("/"), "2000");
            }}
          >
            Start
          </motion.button>
        </AnimatePresence>
      </motion.div>
      <div className="septima-logo">
        <img src="/images/Septima_logo.svg" alt="" />
      </div>
    </div>
  );
};

export default SplashTwo;
