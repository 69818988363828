export default function IconHighscores({
  height,
  width,
  fill,
  blur,
  classname,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      className={classname}
      aria-hidden="true"
      role="icon"
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 20 20"
    >
      <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="50.298"
          height="50.298"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "#00fe78"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="">
        <path
          id="leaderboard_FILL0_wght400_GRAD0_opsz48"
          d="M6.038,14.192V22.91h4.615V14.192Zm6.154-6.154V22.91h4.615V8.038Zm6.154,8.205V22.91h4.615V16.244Zm4.615,8.205H6.038A1.532,1.532,0,0,1,4.5,22.91V14.192a1.532,1.532,0,0,1,1.538-1.538h4.615V8.038A1.532,1.532,0,0,1,12.192,6.5h4.615a1.532,1.532,0,0,1,1.538,1.538v6.667h4.615A1.532,1.532,0,0,1,24.5,16.244V22.91a1.532,1.532,0,0,1-1.538,1.538Z"
          transform="translate(-4.5 -6.5)"
          fill={fill ? fill : "#6C7270"}
        />
      </g>
    </svg>
  );
}
