import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="background">
      <div className="container">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 0.2, 0, 0.2, 0, 0.4, 1] }}
          transition={{ delay: 0.1, duration: 0.8 }}
          className="headline"
        >
          Page not found
        </motion.h1>
        <p className="text-regular">
          we looked but to no avail... Click the link below to return to the
          main page
        </p>
        <Link to="/" className="secondary-btn">
          go back
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
