import { useContext } from "react";
import { Link } from "react-router-dom";
import { SuccesContext } from "../contexts/SuccesContext";
import { motion } from "framer-motion";

const Succes = () => {
  const { numberOfMoves, setIsGameWon, setNumberOfMoves } =
    useContext(SuccesContext);

  return (
    <div className="background background-centered">
      <h1 className="headline">Tillykke</h1>
      <p className="text-regular">
        Flot klaret! Insend dit resultat eller klik på spil igen for at vende
        tilbage til spillet.
      </p>
      <p className="text-regular">Træk: {numberOfMoves}</p>
      <Link to="/submitscore">
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, scale: [1, 1.1, 1] }}
          transition={{
            delay: 2.2,
            duration: 0.2,
            type: "spring",
            bounce: 1,
          }}
          className="submit-score-btn"
        >
          indsend
        </motion.button>
      </Link>

      <button
        className="secondary-btn"
        onClick={() => {
          setIsGameWon(false);
          setNumberOfMoves(0);
        }}
      >
        spil igen
      </button>
    </div>
  );
};

export default Succes;
