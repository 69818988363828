//check if array is solvable
export const checkIfSolvable = (arr) => {
  // console.log("checkSolvable called with : " + arr);
  var inversions = 0;
  for (var i = 0; i < arr.length; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[j] > arr[i]) {
        inversions++;
      }
    }
  }
  if (inversions % 2 === 1) {
    // console.log("It's Unsolvable");
    return false;
  } else {
    // console.log("It's Solvable");
    return true;
  }
};
