import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import SplashProvider from "./contexts/SplashContext";
import "./styles/app.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
const logCss =
  "color: #010604; font-size: 1rem; font-weight: 400; line-height: 1.4rem; font-family:'Monserrat', sans-serif;";
const style = "line-height: 1rem;";

console.log(
  `%c
  hello
                          . .   ./&&&/.  ..     
                        (&@/           &&&&&&&&/             
                  %&*   @@%%   @&#. ,&&&&.      (&(         
                *&&&&@&&@     @@                    &.      
              %&&&&&&&&&&/    &&&&&&&@             @% &/    
              &&&&&&&&&&&&&&&&&&&&& .                %&&&   
           /@   &&&&&&&&&&&&&&&&&& %                 &&&%&&, 
          ,@  (&&&&&&&&&&&&&&&.                       @&@  &. 
          &    &&&&&&&&&&&&&@                       ,&&&&&&&&
         ,@      %@&&&&     @                      &&&&&&&&&&
         &&        &&(    ( @                      @&&&&&&&&&
         ,@            &&/,                         #&&&&&&&&
          &               & ,                            &&&
          .&                (@&&&&&&                      && 
           *@               /&&&&&&&&&&&                 @&. 
            &             %&&&&&&&&&&&&&&&&&            &   
              (&            @&&&&&&&&&&&&&            &*    
                .&.             &&&&&&&&&&         .&       
                    /&(        @&&&&&&%         %&*         
                        *&&#   &&@#      %&&,             
                              .   .   .                   world
`,
  style
);

console.log(
  `%cNysgerrighed er godt, og DIN nysgerrighed har bragt dig til denne destination: Kildekoden 🌎 I Septima er vi også meget nysgerrige - vi elsker at udforske og benytte den sidste nye teknologi.`,
  logCss
);
console.log(
  `%cEr du nysgerrig efter, hvad Septima kan tilbyde eller er du interesseret i job eller praktik, så kan du finde mere info på www.septima.dk`,
  logCss
);
console.log(`%cGame of Maps er bygget af Ida, Anne, Niels og Gregers.`, logCss);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SplashProvider>
        <App />
      </SplashProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
