import { NavLink } from "react-router-dom";

const NavBtn = ({ link, icon, text, setEnd }) => {
  let activeClassName = "nav-link--active";

  return (
    <div className="nav-btn">
      <NavLink
        to={link}
        className={({ isActive }) => (isActive ? activeClassName : undefined)}
        end={setEnd && "end"}
      >
        {icon}
        <p>{text}</p>
      </NavLink>
    </div>
  );
};

export default NavBtn;
