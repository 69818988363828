export default function SeptimaLogoNeon2({
  height,
  width,
  fill,
  blur,
  classname,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={classname}
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 68 68"
    >
      <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "lime"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_35"
        data-name="Group 35"
        transform="translate(-207.927 -57.068)"
      >
        <path
          id="Line_4"
          data-name="Line 4"
          d="M0,10.6a1.251,1.251,0,0,1-.85-.332A1.255,1.255,0,0,1-.924,8.495L7.677-.85A1.256,1.256,0,0,1,9.451-.924,1.255,1.255,0,0,1,9.525.85L.924,10.2A1.252,1.252,0,0,1,0,10.6Z"
          transform="translate(216.533 107.202)"
          fill={fill ? fill : "lime"}
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M94.8,17.927H160.88a1.255,1.255,0,0,1,1.255,1.256V85.26a1.255,1.255,0,0,1-1.255,1.256H106.11a1.255,1.255,0,0,1-1.194-.867A16.126,16.126,0,0,0,94.427,75.264a1.256,1.256,0,0,1-.88-1.2V19.183A1.255,1.255,0,0,1,94.8,17.927Zm64.823,2.511H96.058v52.73A18.648,18.648,0,0,1,107,84h52.627Z"
          transform="translate(114.38 39.14)"
          fill={fill ? fill : "lime"}
        />
      </g>
    </svg>
  );
}
