import { useEffect, useState, useContext } from "react";
import axios from "axios";
//import { PacmanLoader } from "react-spinners";
import { AnimatePresence, motion } from "framer-motion";
import Spinner from "./Spinner";
import IconRocket from "./icons/IconRocket";
import IconParagliding from "./icons/IconParagliding";
import { LevelContext } from "../contexts/LevelContext";

const Scores = () => {
  const { level } = useContext(LevelContext);

  const [isLoading, setIsLoading] = useState(true);
  const [toggleScoreBoard, setToggleScoreBoard] = useState(
    level === "hard" ? true : false
  );
  const [ekspertLevel, setEkspertLevel] = useState();
  const [noviceLevel, setNoviceLevel] = useState();

  useEffect(() => {
    axios
      .get("https://kortdage21.k8s-test-121.septima.dk/scoreboard?limit=1000", {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        const filteredData = res.data.filter((item) => {
          return item.dato > "2022-10-24T10:46:20.721868";
        });
        const sortedScores = filteredData.sort((a, b) => a.point - b.point);

        const ekspert = sortedScores?.filter((user) => {
          return user.level === 1;
        });
        const novice = sortedScores?.filter((user) => {
          return user.level === 2;
        });
        setEkspertLevel(ekspert);
        setNoviceLevel(novice);
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <AnimatePresence>
      <motion.div
        className="scores-loader"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
        key="scores-loader"
      >
        {/* <PacmanLoader color="rgb(189, 189, 189)" /> */}
        <Spinner />
      </motion.div>
    </AnimatePresence>
  ) : (
    <div className="scores-container">
      <header className="scores-header">
        <div
          className="scores-header-item"
          onClick={() => setToggleScoreBoard(true)}
        >
          <IconRocket fill={toggleScoreBoard && "#00ff00"} width="16" />
          <h3
            className={`scores-header-item-level ${
              toggleScoreBoard ? "active" : ""
            }`}
          >
            Ekspert
          </h3>
        </div>
        <div
          className="scores-header-item"
          onClick={() => setToggleScoreBoard(false)}
        >
          <IconParagliding fill={!toggleScoreBoard && "#00ff00"} width="16" />
          <h3
            className={`scores-header-item-level ${
              !toggleScoreBoard ? "active" : ""
            }`}
          >
            Begynder
          </h3>
        </div>
      </header>
      <motion.div
        className="scores-content-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className={`arrow-up ${!toggleScoreBoard ? "left" : ""}`}></div>
        <div className="scores-content">
          <div className="scores-item scores-content-header">
            <p className="scores-item-placement  scores-item-p scores-content-header">
              #
            </p>
            <p className="scores-item-name scores-item-p scores-content-header">
              Navn
            </p>
            <p className="scores-item-score scores-item-p scores-content-header">
              Træk
            </p>
          </div>
          {toggleScoreBoard
            ? ekspertLevel?.map((item, index) => (
                <div key={index} className="scores-item">
                  <p className="scores-item-placement scores-item-p">
                    {index + 1}
                  </p>
                  <p className="scores-item-name scores-item-p">
                    {item.brugernavn}
                  </p>
                  <p className="scores-item-score scores-item-p">
                    {item.point}
                  </p>
                </div>
              ))
            : noviceLevel?.map((item, index) => (
                <div key={index} className="scores-item">
                  <p className="scores-item-placement scores-item-p">
                    {index + 1}
                  </p>
                  <p className="scores-item-name scores-item-p">
                    {item.brugernavn}
                  </p>
                  <p className="scores-item-score scores-item-p">
                    {item.point}
                  </p>
                </div>
              ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Scores;
