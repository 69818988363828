export default function IconChevronUp({ height, width, fill, blur }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="icon"
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 37 37"
    >
      <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="50.298"
          height="50.298"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "#00fe78"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <path
        d="M12.75 29.4q-.55-.65-.575-1.45-.025-.8.575-1.4l9.85-9.85q.3-.3.65-.45.35-.15.75-.15t.75.15q.35.15.65.45l9.9 9.9q.55.55.55 1.375t-.6 1.425q-.6.6-1.425.6-.825 0-1.425-.6L24 21l-8.4 8.4q-.6.6-1.4.575-.8-.025-1.45-.575Z"
        // transform="translate(-3.75 -4.09)"
        fill={fill ? fill : "#6C7270"}
      />
    </svg>
  );
}
