import { useContext } from "react";
import { SuccesContext } from "../contexts/SuccesContext";
import { LevelContext } from "../contexts/LevelContext";
import PuzzleHard from "../components/PuzzleHard";
import PuzzleEasy from "../components/PuzzleEasy";
import Succes from "../components/Succes";

const Home = () => {
  //contexts
  const { isGameWon } = useContext(SuccesContext);
  const { level } = useContext(LevelContext);

  return (
    <div className="background screenorientation-landscape">
      {!isGameWon ? (
        level === "hard" ? (
          <PuzzleHard />
        ) : (
          <PuzzleEasy />
        )
      ) : (
        <Succes />
      )}
    </div>
  );
};

export default Home;
