import { Routes, Route } from "react-router-dom";
import React, { useContext } from "react";
import { SplashContext } from "./contexts/SplashContext";
import SuccesProvider from "./contexts/SuccesContext.js";
import ThemeProvider from "./contexts/ThemeContext.js";
import LevelProvider from "./contexts/LevelContext.js";
import Home from "./pages/Home.js";
import Splash from "./pages/Splash.js";
import Scoreboard from "./pages/Scoreboard";
import SubmitScore from "./pages/SubmitScore.js";
import SplashTwo from "./pages/SplashTwo.js";
import NotFound from "./pages/NotFound.js";
import ChooseLevel from "./pages/ChooseLevel.js";
import PWAPrompt from "react-ios-pwa-prompt";
import About from "./pages/About";

function App() {
  const { isFirstTime } = useContext(SplashContext);

  return (
    <>
      <ThemeProvider>
        <LevelProvider>
          <SuccesProvider>
            <Routes>
              {isFirstTime ? (
                <>
                  <Route path="/" element={<Splash />} />
                  <Route path="onboarding" element={<SplashTwo />} />
                </>
              ) : (
                <>
                  <Route strict path="/" element={<Home />} />
                  <Route path="scoreboard" element={<Scoreboard />} />
                  <Route path="submitscore" element={<SubmitScore />} />
                  <Route path="chooselevel" element={<ChooseLevel />} />
                  <Route path="about" element={<About />} />
                </>
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </SuccesProvider>
        </LevelProvider>
      </ThemeProvider>
      <PWAPrompt />
    </>
  );
}
export default App;
