import { useState, useEffect, useContext } from "react";
import { SuccesContext } from "../contexts/SuccesContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { mapThemesArray } from "../data/image_arrays";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { motion } from "framer-motion";
import IconRefresh from "./icons/IconRefresh";
import Navigation from "./Navigation";
import jsConfetti from "../confetti";
import IconShow from "./icons/IconShow";
import IconPuzzleFill from "./icons/IconPuzzleFill";
import { isAscending } from "../utils/ascending";
import { shuffle } from "../utils/shuffle";

const PuzzleEasy = () => {
  //contexts
  const { numberOfMoves, setNumberOfMoves, setIsGameWon } =
    useContext(SuccesContext);
  const { theme, setTheme } = useContext(ThemeContext);

  //stop long press pop-up from appearing on android
  // window.oncontextmenu = function (event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   return false;
  // };

  //state variables
  const [flip, setFlip] = useState(false);
  const [savedTiles, setSavedTiles] = useState([]);

  //functions to change map theme
  const nextMapTheme = () => {
    //if (theme < arrayofmaps.length - 1) {
    if (theme < mapThemesArray.length - 1) {
      setTheme(theme + 1);
    } else {
      setTheme(0);
    }
  };
  const prevMapTheme = () => {
    if (theme > 0) {
      setTheme(theme - 1);
    } else {
      // setTheme(arrayofmaps.length - 1);
      setTheme(mapThemesArray.length - 1);
    }
  };

  //variables defining size of puzzle
  let numberOfSquares = 9;
  //let numberOfRows = 3;

  //constructs a new array with Array.from() constructor: Array.from(arrayLike, (element, index) => { /* … */ } )
  //first param: array like object, second param: arrow function that returns element and index number
  const squares = Array.from(
    { length: numberOfSquares },
    (square, index) => index
  );

  //state variables for our random squares (empty array) and number of moves (0)
  const [randomSquares, setRandomSquares] = useState([]);

  //sideeffect: shuffles squares (runs only on first render / page refresh)
  useEffect(() => {
    setRandomSquares(() => shuffle(squares));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //on first move: if squares have ascending order ex:(0,1,2,3) shuffle them again
  useEffect(() => {
    if (randomSquares.length === 0) return;
    if (numberOfMoves === 0 && isAscending(randomSquares)) {
      setRandomSquares(() => shuffle(squares));
      //console.log(randomSquares, "Squares have been shuffled again");
    } else if (numberOfMoves !== 0 && isAscending(randomSquares)) {
      celebration();
      setNumberOfMoves(numberOfMoves);
      setIsGameWon(true);
      //console.log("squares are in correct order");
    }
  }, [randomSquares, squares, numberOfMoves]); // eslint-disable-line react-hooks/exhaustive-deps

  //function to swap two clicked tiles and update number of moves
  const swap = (first, second) => {
    var arr = [...randomSquares];
    var b = arr[first];
    arr[first] = arr[second];
    arr[second] = b;
    setSavedTiles([]);
    setNumberOfMoves((prev) => prev + 1);
    return setRandomSquares([...arr]);
  };

  //when square is clicked: if clicked square isn't already selected - save index number in state var
  const handleSwap = (item, index) => {
    savedTiles.includes(index)
      ? setSavedTiles([])
      : setSavedTiles([...savedTiles, index]);
  };

  useEffect(() => {
    if (savedTiles.length > 1) {
      swap(...savedTiles);
    }
  }, [savedTiles, setSavedTiles]); // eslint-disable-line react-hooks/exhaustive-deps

  //when reset btn is clicked: shuffle squares again and set number of moves to 0
  const handleGameReset = () => {
    setRandomSquares(() => shuffle(squares));
    setNumberOfMoves(0);
  };

  const celebration = () => {
    let options = {
      confettiNumber: 500,
      confettiRadius: 3,
      emojiSize: 40,
      emojis: ["🧩", "⭐️"],
    };
    jsConfetti.addConfetti(options);
  };

  const numberBreakPoint = () => {
    if (numberOfMoves > 99) {
      return "puzzle-moves-100";
    } else if (numberOfMoves > 9) {
      return "puzzle-moves-10";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="puzzle-header">
        <div className={`puzzle-moves ${numberBreakPoint()}`}>
          <h2 className="puzzle-moves-count">{numberOfMoves}</h2>
          <h2 className="puzzle-moves-headline">træk</h2>
        </div>
        <div className="puzzle-reset">
          <button aria-label="reset game" className="puzzle-reset-btn">
            <motion.div
              className="puzzle-reset-icon"
              onClick={() => handleGameReset()}
              transition={{ duration: 0.36 }}
            >
              <IconRefresh width="23" height="23" fill="#8ca39a" />
            </motion.div>
          </button>
          <p className="puzzle-reset-text">Genstart</p>
        </div>
      </div>
      <div style={{ maxWidth: "500px" }}>
        <div className={`puzzle-container ${flip ? "flip" : ""}`}>
          <div className="puzzle front">
            {randomSquares?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`puzzle-item ${
                    item === index ? "square-rightPos" : ""
                  }`}
                >
                  <div
                    className={`FillSquare ${
                      savedTiles.includes(index) ? "ClickedSquare" : ""
                    }`}
                    onClick={() => handleSwap(item, index)}
                  >
                    <div
                      className={`puzzle-item-img puzzle-item-imgnr${item}`}
                      style={
                        savedTiles.includes(index)
                          ? {
                              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/images/map_${
                                theme + 1
                              }.png')`,
                            }
                          : {
                              backgroundImage: `url('/images/map_${
                                theme + 1
                              }.png')`,
                            }
                      }
                    >
                      {/* {item} */}
                    </div>
                    {/* <img
                      src={arrayofmaps[theme][item]}
                      alt={`puzzle piece no ${item}`}
                      className="puzzle-item-img"
                    /> */}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="full-map-container back">
            <img
              src={mapThemesArray[theme].imgurl}
              alt="full map"
              className="full-map"
            />
            <div className="full-map-label">
              <a
                href={mapThemesArray[theme].attributionLink}
                className="full-map-label-link"
                rel="noreferrer"
                target="_blank"
              >
                {mapThemesArray[theme].attributionLinkText}
              </a>
              <p className="full-map-label-attr">
                {mapThemesArray[theme].attribution}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-btn-container">
        <button
          className="theme-btn "
          aria-label="skip to previous map theme"
          onClick={prevMapTheme}
        >
          <FaChevronLeft className="theme-btn-icon" />
        </button>
        <button
          className={`theme-title-container ${
            flip && "theme-title-container--active"
          }`}
          onClick={() => setFlip(!flip)}
        >
          <h3 className="theme-title">{mapThemesArray[theme].title}</h3>
          {flip ? (
            <div className="theme-title-showmap-container">
              <IconPuzzleFill width="12" height="18" />
              <p className="theme-title-showmap">Spil</p>
            </div>
          ) : (
            <div className="theme-title-showmap-container">
              <IconShow fill="#00ff00" width="20" height="20" />
              <p className="theme-title-showmap">Se kort</p>
            </div>
          )}
        </button>
        <button
          className="theme-btn"
          aria-label="skip to next map theme"
          onClick={nextMapTheme}
        >
          <FaChevronRight className="theme-btn-icon" />
        </button>
      </div>
      <Navigation />
    </>
  );
};
export default PuzzleEasy;
