//import { AnimatePresence, motion } from "framer-motion";
import IconPuzzle from "./icons/IconPuzzle";
import IconRocket from "./icons/IconRocket";
import IconHighscores from "./icons/IconHighscores";
import IconInfo from "./icons/IconInfo";
import NavBtn from "./NavBtn";

const Navigation = () => {
  return (
    <div className="nav">
      <NavBtn
        link="/"
        icon={<IconPuzzle width="20" height="20" classname="nav-icon" />}
        text="Spil"
        home={true}
        setEnd
      />
      <NavBtn
        link="/chooselevel"
        icon={<IconRocket width="20" height="20" classname="nav-icon" />}
        text="Niveau"
        home={false}
      />
      <NavBtn
        link="/scoreboard"
        icon={<IconHighscores width="20" height="20" classname="nav-icon" />}
        text="Topscores"
        home={false}
      />
      <NavBtn
        link="/about"
        icon={<IconInfo width="20" height="20" classname="nav-icon" />}
        text="Om spillet"
        home={false}
      />
    </div>
  );
};

export default Navigation;
