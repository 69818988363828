import AboutMaps from "../components/AboutMaps";
import Navigation from "../components/Navigation";
import { mapThemesArray } from "../data/image_arrays";

const About = () => {
  return (
    <>
      <div className="background">
        <h3 className="page-headline">Om spillet</h3>
        <div className="container">
          <div className="aboutmaps-content">
            <p className="aboutmaps-vektor-tiles">
              Spillet er udviklet af
              <a href="https://septima.dk/" style={{ fontWeight: "600" }}>
                <span> Septima </span>
              </a>
              til kortdage.
            </p>
            <p className="aboutmaps-vektor-tiles">
              Septimas baggrundskort bygger på den nyeste teknologi, Vector
              Tiles. Det betyder, at kortet står skarpt på skærme i alle
              opløsninger og størrelser. Det samme er tilfældet med bynavne og
              vejnavne.
            </p>
            <h4 className="aboutmaps-sm-headline">Kilder</h4>
          </div>
          <ul className="aboutmaps-list">
            {mapThemesArray?.map((item) => {
              return (
                <AboutMaps
                  key={item.title}
                  imgurl={item.imgurl}
                  title={item.title}
                  link={item.attributionLink}
                  attribution={item.attribution}
                />
              );
            })}
          </ul>
        </div>
      </div>
      <Navigation />
    </>
  );
};

export default About;
