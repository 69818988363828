export default function IconInfo({ height, width, fill, blur, classname }) {
  //const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="icon"
      className={classname}
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 20 20"
    >
      {/* width="50.298" height="50.298" */}
      <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="50.298"
          height="50.298"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "#00fe78"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="">
        <path
          id="info_FILL0_wght500_GRAD0_opsz48"
          d="M12.767,17.2a.719.719,0,0,0,.732-.732V12.523a.665.665,0,0,0-.222-.5.719.719,0,0,0-.51-.211.719.719,0,0,0-.732.732v3.946a.665.665,0,0,0,.222.5.719.719,0,0,0,.51.211ZM12.7,10.262a.818.818,0,0,0,.587-.222.76.76,0,0,0,.233-.576.834.834,0,0,0-.233-.6.83.83,0,0,0-1.175,0,.834.834,0,0,0-.233.6.76.76,0,0,0,.233.576A.818.818,0,0,0,12.7,10.262Zm0,11.438a9.168,9.168,0,0,1-3.558-.687,8.631,8.631,0,0,1-4.755-4.755A9.168,9.168,0,0,1,3.7,12.7a9.11,9.11,0,0,1,.687-3.536,8.749,8.749,0,0,1,1.9-2.86A8.9,8.9,0,0,1,9.142,4.4,9.041,9.041,0,0,1,12.7,3.7a8.985,8.985,0,0,1,3.536.7A8.9,8.9,0,0,1,21.7,12.7a9.041,9.041,0,0,1-.7,3.558,8.9,8.9,0,0,1-1.906,2.86,8.749,8.749,0,0,1-2.86,1.9A9.11,9.11,0,0,1,12.7,21.7ZM12.7,12.7Zm0,7.493A7.23,7.23,0,0,0,17.987,18a7.2,7.2,0,0,0,2.206-5.3,7.212,7.212,0,0,0-2.206-5.287A7.212,7.212,0,0,0,12.7,5.207,7.212,7.212,0,0,0,7.413,7.413,7.212,7.212,0,0,0,5.207,12.7,7.2,7.2,0,0,0,7.413,18,7.23,7.23,0,0,0,12.7,20.193Z"
          transform="translate(-3.7 -3.7)"
          fill={fill ? fill : "#6C7270"}
        />
      </g>
    </svg>
  );
}
