import { createContext, useState, useEffect } from "react";

export const SplashContext = createContext(true);

const SplashProvider = ({ children }) => {
  const fromSS = JSON.parse(sessionStorage.getItem("first-visit"));

  const [isFirstTime, setIsFirstTime] = useState(
    fromSS === null ? true : fromSS
  );

  useEffect(() => {
    sessionStorage.setItem("first-visit", isFirstTime);
  }, [isFirstTime]);

  useEffect(() => {
    setIsFirstTime(JSON.parse(sessionStorage.getItem("first-visit")));
  }, [isFirstTime]);

  return (
    <SplashContext.Provider value={{ isFirstTime, setIsFirstTime }}>
      {children}
    </SplashContext.Provider>
  );
};

export default SplashProvider;
