export default function SeptimaLogoNeon3({
  height,
  width,
  fill,
  blur,
  classname,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={classname}
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 68 68"
    >
      <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "lime"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_36"
        data-name="Group 36"
        transform="translate(-132.271 -133.167)"
      >
        <path
          id="Line_5"
          data-name="Line 5"
          d="M0,10.592a1.251,1.251,0,0,1-.85-.332A1.256,1.256,0,0,1-.924,8.486L7.67-.85A1.256,1.256,0,0,1,9.444-.924,1.256,1.256,0,0,1,9.518.85L.924,10.186A1.252,1.252,0,0,1,0,10.592Z"
          transform="translate(183.542 143.057)"
          fill={fill ? fill : "lime"}
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M19.146,94.026H72.9a1.255,1.255,0,0,1,1.21.919A16.124,16.124,0,0,0,85.541,106.2a1.255,1.255,0,0,1,.939,1.215v53.947a1.256,1.256,0,0,1-1.256,1.256H19.146a1.256,1.256,0,0,1-1.256-1.256V95.282A1.256,1.256,0,0,1,19.146,94.026Zm52.822,2.511H20.4V160.1H83.968V108.355A18.655,18.655,0,0,1,71.968,96.537Z"
          transform="translate(114.38 39.14)"
          fill={fill ? fill : "lime"}
        />
      </g>
    </svg>
  );
}
