export default function IconRocket({ height, width, fill, blur, classname }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="icon"
      className={classname}
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 20 20"
    >
      <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="50.298"
          height="50.298"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "#00fe78"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="">
        <path
          id="rocket_launch_FILL0_wght400_GRAD0_opsz48"
          d="M8.858,12.851q.424-.847.906-1.671T10.788,9.6l-1.86-.377L6.363,11.792ZM20.98,5.507a11.408,11.408,0,0,0-4.331,1.024,13.513,13.513,0,0,0-3.766,2.742,12.24,12.24,0,0,0-1.506,1.907A18.5,18.5,0,0,0,9.988,13.7l3.131,3.131a18.5,18.5,0,0,0,2.519-1.389,12.24,12.24,0,0,0,1.907-1.506,13.584,13.584,0,0,0,2.742-3.754A11.312,11.312,0,0,0,21.31,5.86a.444.444,0,0,0-.024-.141.3.3,0,0,0-.071-.118.314.314,0,0,0-.106-.071A.346.346,0,0,0,20.98,5.507ZM15.4,11.439a1.677,1.677,0,1,1,1.165.471A1.583,1.583,0,0,1,15.4,11.439Zm-1.436,6.544,1.059,2.472,2.566-2.566-.353-1.86q-.753.541-1.589,1.036T13.966,17.983Zm8.78-13.158a13.246,13.246,0,0,1-.953,5.426,14.482,14.482,0,0,1-3.237,4.7.185.185,0,0,1-.047.035.184.184,0,0,0-.047.035l.518,2.589a1.43,1.43,0,0,1-.024.683,1.348,1.348,0,0,1-.353.588L15.284,22.2a.657.657,0,0,1-.624.188.65.65,0,0,1-.506-.424l-1.6-3.7-3.978-4L4.856,12.686a.628.628,0,0,1-.412-.518.7.7,0,0,1,.2-.636l3.3-3.3a1.454,1.454,0,0,1,.588-.365,1.338,1.338,0,0,1,.683-.035l2.589.518a.184.184,0,0,1,.047-.035.184.184,0,0,0,.047-.035,14.523,14.523,0,0,1,4.684-3.237,13.167,13.167,0,0,1,5.414-.953.647.647,0,0,1,.271.059.848.848,0,0,1,.247.177.922.922,0,0,1,.177.235.571.571,0,0,1,.059.259ZM5.492,17.112A2.779,2.779,0,0,1,7.5,16.276,2.774,2.774,0,0,1,10.329,19.1a2.779,2.779,0,0,1-.836,2.013A4.592,4.592,0,0,1,7.6,22.125a25.649,25.649,0,0,1-3.849.753,27.486,27.486,0,0,1,.741-3.86A4.5,4.5,0,0,1,5.492,17.112Zm.989,1.012a3.118,3.118,0,0,0-.588,1.106,11.838,11.838,0,0,0-.447,1.93,11.838,11.838,0,0,0,1.93-.447,3.118,3.118,0,0,0,1.106-.588,1.324,1.324,0,0,0,.459-1,1.41,1.41,0,0,0-1.459-1.459,1.324,1.324,0,0,0-1,.459Z"
          transform="translate(-3.75 -4.09)"
          fill={fill ? fill : "#6C7270"}
        />
      </g>
    </svg>
  );
}
