import { useContext, useState } from "react";
import { SuccesContext } from "../contexts/SuccesContext";
import { LevelContext } from "../contexts/LevelContext";
import { AnimatePresence, motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Scores from "../components/Scores";

const SubmitScore = () => {
  //state variables
  const [contactFeedback, setContactFeedback] = useState(false);
  const [scoreIsSubmitted, setScoreIsSubmitted] = useState(false);

  //context
  const { numberOfMoves, setNumberOfMoves, setIsGameWon } =
    useContext(SuccesContext);
  const { level } = useContext(LevelContext);

  //useForm object from react-hook-form
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  let navigate = useNavigate();

  async function onSubmit(data) {
    if (data) {
      const body = {
        brugernavn: data.name,
        email: data.email,
        userid: data.name,
        point: data.score,
        level: data.level,
        samtykke: true,
        nyhedsbrev: true,
      };
      //console.log(body);
      await fetch("https://kortdage21.k8s-test-121.septima.dk/gamedata", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      }).catch((err) => console.error("Adding score failed: ", err));
      //console.log("Score is submitted!");
      setContactFeedback(true);
      setNumberOfMoves(0);
      setScoreIsSubmitted(true);
    } else {
      //console.log("There is no data");
    }
  }

  return (
    <div className="submit background">
      {!scoreIsSubmitted ? (
        <AnimatePresence>
          <motion.form
            className=" submit-form container"
            onSubmit={handleSubmit(onSubmit)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, scale: 0.8, duration: 1 }}
            transition={{ duration: 0.4 }}
          >
            <div className="submit-form-input-block">
              <label htmlFor="name" className="submit-form-input-label">
                Navn
              </label>
              <input
                className="submit-form-input-field"
                placeholder="Navn"
                type="text"
                {...register("name", {
                  required: "Skriv venligst dit navn",
                  minLength: { value: 2, message: "Navn er for kort" },
                  maxLength: { value: 15, message: "Navn er for langt" },
                  onChange: (e) => {
                    if (e.target.value.length <= 2) {
                      setError("name", {
                        message: "Navn er for kort",
                      });
                    } else if (e.target.value.length > 15) {
                      setError("name", {
                        message: "Navn er for langt",
                      });
                    } else {
                      setError("name");
                    }
                  },
                })}
              />
              {errors?.name && (
                <div className="submit-form-input-error">
                  <p className="submit-form-input-error-msg">
                    {errors.name.message}
                  </p>
                </div>
              )}
            </div>
            <div className="submit-form-input-block">
              <label htmlFor="email" className="submit-form-input-label">
                Email
              </label>
              <input
                className="submit-form-input-field"
                placeholder="Email"
                type="email"
                {...register("email", {
                  required: "Skriv venligst en korrekt email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Skriv venligst en korrekt email",
                  },
                })}
              />
              {errors?.email && (
                <div className="submit-form-input-error">
                  <p className="submit-form-input-error-msg">
                    {errors.email.message}
                  </p>
                </div>
              )}
            </div>
            <div className="submit-form-input-block">
              <label
                style={{ display: "none" }}
                htmlFor="level"
                className="submit-form-input-label "
              >
                Niveau
              </label>
              <input
                className="submit-form-input-field your-level"
                type="hidden"
                placeholder={`Niveau ${level}`}
                value={level === "hard" ? 1 : 2}
                readOnly
                {...register("level")}
              />
            </div>
            <div className="submit-form-input-block">
              <label htmlFor="score" className="submit-form-input-label ">
                Antal træk
              </label>
              <input
                className="submit-form-input-field your-score"
                type="number"
                placeholder="Dine træk"
                value={numberOfMoves}
                readOnly
                {...register("score")}
              />
            </div>

            <motion.button
              type="submit"
              className="main-btn submit-form-btn"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, scale: [1, 1.1, 1] }}
              transition={{
                duration: 0.4,
                bounce: 1,
              }}
            >
              Indsend
            </motion.button>
          </motion.form>
        </AnimatePresence>
      ) : (
        <div className="" style={{ padding: "1rem" }}>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 0] }}
            transition={{ duration: 4 }}
            onAnimationComplete={() => {
              contactFeedback && setContactFeedback(false);
              setIsGameWon(false);
              //navigate("/");
            }}
            className="submit-form-feedback"
          >
            din score er indsendt!
          </motion.p>
          <Scores level={level} />
        </div>
      )}
      <button
        className="secondary-btn"
        onClick={() => {
          setIsGameWon(false);
          navigate("/");
        }}
      >
        spil igen
      </button>
    </div>
  );
};

export default SubmitScore;
