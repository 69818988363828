const AboutMaps = ({ imgurl, title, link, attribution }) => {
  return (
    <li>
      <a
        href={link ? link : "/about"}
        target={link ? "_blank" : "_parent"}
        rel="noreferrer noopener"
        className="aboutmaps-item"
      >
        <div className="aboutmaps-item-img">
          <img src={imgurl} alt={title} />
        </div>
        <div className="aboutmaps-item-text">
          <h3 className="aboutmaps-item-headline">{title}</h3>
          <p className="aboutmaps-item-description">{attribution}</p>
        </div>
      </a>
    </li>
  );
};

export default AboutMaps;
