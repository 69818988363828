export default function SeptimaLogoNeon1({
  height,
  width,
  fill,
  blur,
  classname,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={classname}
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 68 68"
    >
      {/* <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="70"
          height="70"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "lime"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs> */}
      <g
        id="Group_34"
        data-name="Group 34"
        transform="translate(-132.271 -57.069)"
      >
        <path
          id="Line_6"
          data-name="Line 6"
          d="M9.1,9.642a1.251,1.251,0,0,1-.851-.332L-.851.923A1.256,1.256,0,0,1-.923-.851,1.255,1.255,0,0,1,.851-.923l9.1,8.386A1.256,1.256,0,0,1,9.1,9.642Z"
          transform="translate(181.826 109.041)"
          // transform="translate(160 109.041)"
          fill={fill ? fill : "lime"}
          // filter="url(#Shape)"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M73.105,86.516H19.146A1.256,1.256,0,0,1,17.89,85.26V19.184a1.256,1.256,0,0,1,1.256-1.256H85.224a1.256,1.256,0,0,1,1.256,1.256v54.67a1.256,1.256,0,0,1-.939,1.215A16.118,16.118,0,0,0,74.3,85.649,1.255,1.255,0,0,1,73.105,86.516ZM20.4,84H72.218A18.635,18.635,0,0,1,83.968,72.911V20.44H20.4Z"
          transform="translate(114.38 39.14)"
          // transform="translate(122 44)"
          fill={fill ? fill : "lime"}
          // filter="url(#Shape)"
        />
      </g>
    </svg>
  );
}
