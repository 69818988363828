import { useState, useEffect, useContext } from "react";
import { SuccesContext } from "../contexts/SuccesContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { mapThemesArray } from "../data/image_arrays";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import IconShow from "./icons/IconShow";
import { motion } from "framer-motion";
import IconRefresh from "./icons/IconRefresh";
import Navigation from "./Navigation";
import jsConfetti from "../confetti";
import IconPuzzleFill from "./icons/IconPuzzleFill";
import { checkIfSolvable } from "../utils/solvable";
import { isAscending } from "../utils/ascending";
import { shuffle } from "../utils/shuffle";

const PuzzleHard = () => {
  //state variables
  const [flip, setFlip] = useState(false);

  //contexts
  const { numberOfMoves, setNumberOfMoves, setIsGameWon } =
    useContext(SuccesContext);
  const { theme, setTheme } = useContext(ThemeContext);

  //stop long press pop-up from appearing on android
  // window.oncontextmenu = function (event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   return false;
  // };

  //functions to change map theme
  const nextMapTheme = () => {
    if (theme < mapThemesArray.length - 1) {
      setTheme(theme + 1);
    } else {
      setTheme(0);
    }
  };
  const prevMapTheme = () => {
    if (theme > 0) {
      setTheme(theme - 1);
    } else {
      setTheme(mapThemesArray.length - 1);
    }
  };

  //variables defining size of puzzle
  let numberOfSquares = 9;
  let numberOfRows = 3;

  //constructs a new array with Array.from() constructor: Array.from(arrayLike, (element, index) => { /* … */ } )
  //first param: array like object, second param: arrow function that returns element and index number
  const squares = Array.from(
    { length: numberOfSquares },
    (square, index) => index
  );

  //state variables for our random squares (empty array) and number of moves (0)
  const [randomSquares, setRandmoSquares] = useState([]);

  //sideeffect: shuffles squares (runs only on first render / page refresh)
  useEffect(() => {
    setRandmoSquares(() => shuffle(squares));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //on first move: if squares have ascending order ex:(0,1,2,3) shuffle them again
  useEffect(() => {
    if (randomSquares.length === 0) return;

    if (
      (numberOfMoves === 0 && isAscending(randomSquares)) ||
      (numberOfMoves === 0 && !checkIfSolvable(randomSquares))
    ) {
      setRandmoSquares(() => shuffle(squares));
      // console.log(randomSquares, "Squares have been shuffled again");
    } else if (numberOfMoves !== 0 && isAscending(randomSquares)) {
      //some celebration here
      celebration();
      setIsGameWon(true);
      //console.log("squares are in correct order");
    }
    //console.log(randomSquares);
  }, [randomSquares, squares, numberOfMoves, setIsGameWon]); //added setIsGameWon!!!

  function moveSquare(itemValue) {
    let emptySquarePos = randomSquares.indexOf(0); //returns the first index at which a given element can be found in the array
    let valIndex = randomSquares.indexOf(itemValue);
    //if image order number plus number of rows equals the number of the empty square
    //or if image order number minus number of rows equals the number of the empty square
    if (
      valIndex + numberOfRows === emptySquarePos ||
      valIndex - numberOfRows === emptySquarePos
    ) {
      //then run swap function
      swap(valIndex, emptySquarePos);
    } else if (
      valIndex + 1 === emptySquarePos &&
      emptySquarePos % numberOfRows !== 0
    ) {
      swap(valIndex, emptySquarePos);
    } else if (
      valIndex - 1 === emptySquarePos &&
      (emptySquarePos + 1) % numberOfRows !== 0
    ) {
      swap(valIndex, emptySquarePos);
    }
  }

  //updating temporary array and number of moves
  function swap(valIndex, emptySquarePos) {
    let temArray = [...randomSquares];
    temArray[emptySquarePos] = randomSquares[valIndex];
    temArray[valIndex] = 0;
    setRandmoSquares(() => [...temArray]);
    setNumberOfMoves((prev) => prev + 1);
  }

  //when square is clicked: move square and update number of moves
  const handleMoveSquare = (item) => {
    moveSquare(item);
  };

  //when reset btn is clicked: shuffle squares again and set number of moves to 0
  const handleGameReset = () => {
    setRandmoSquares(() => shuffle(squares));
    setNumberOfMoves(0);
  };

  const celebration = () => {
    let options = {
      confettiNumber: 500,
      confettiRadius: 3,
      emojiSize: 40,
      emojis: ["🧩", "⭐️"],
    };
    jsConfetti.addConfetti(options);
  };

  const numberBreakPoint = () => {
    if (numberOfMoves > 99) {
      return "puzzle-moves-100";
    } else if (numberOfMoves > 9) {
      return "puzzle-moves-10";
    } else {
      return "";
    }
  };

  // const instaWin = () => {
  //   setRandmoSquares(squares);
  //   numberOfMoves < 1 && setNumberOfMoves(1);
  // };

  return (
    <>
      <div className="puzzle-header">
        <div className={`puzzle-moves ${numberBreakPoint()}`}>
          <h2 className="puzzle-moves-count">{numberOfMoves}</h2>
          <h2 className="puzzle-moves-headline">træk</h2>
        </div>
        <div className="puzzle-reset">
          <button className="puzzle-reset-btn">
            <motion.div
              className="puzzle-reset-icon"
              onClick={() => handleGameReset()}
              transition={{ duration: 0.36 }}
            >
              <IconRefresh width="23" height="23" fill="#8ca39a" />
            </motion.div>
          </button>
          <p className="puzzle-reset-text">Genstart</p>
        </div>
      </div>
      <div className="" style={{ maxWidth: "500px" }}>
        <div className={`puzzle-container ${flip ? "flip" : ""}`}>
          <div className="puzzle front">
            {randomSquares.map((item, index) => {
              return (
                <div
                  key={item}
                  className={`puzzle-item ${
                    item === index ? "square-rightPos" : ""
                  }`}
                >
                  <div
                    className={item === 0 ? "EmptySquare" : "FillSquare"}
                    onClick={() => handleMoveSquare(item)}
                  >
                    {item === 0 ? (
                      <div
                        className="puzzle-item-img"
                        style={{
                          backgroundColor: "rgba(118, 118, 118, 0.1)",
                        }}
                      ></div>
                    ) : (
                      <div
                        className={`puzzle-item-img puzzle-item-imgnr${item}`}
                        style={{
                          backgroundImage: `url(${mapThemesArray[theme].imgurl})`,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="full-map-container back">
            <img
              src={mapThemesArray[theme].imgurl}
              alt="full map"
              className="full-map"
            />
            <div className="full-map-label">
              <a
                href={mapThemesArray[theme].attributionLink}
                className="full-map-label-link"
                rel="noreferrer"
                target="_blank"
              >
                {mapThemesArray[theme].attributionLinkText}
              </a>
              <p className="full-map-label-attr">
                {mapThemesArray[theme].attribution}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-btn-container">
        <button className="theme-btn" onClick={prevMapTheme}>
          <FaChevronLeft className="theme-btn-icon" />
        </button>
        <button
          className={`theme-title-container ${
            flip ? "theme-title-container--active" : ""
          }`}
          onClick={() => setFlip(!flip)}
        >
          <h3 className="theme-title">{mapThemesArray[theme].title}</h3>
          {flip ? (
            <div className="theme-title-showmap-container">
              <IconPuzzleFill width="12" height="18" />
              <p className="theme-title-showmap">Spil</p>
            </div>
          ) : (
            <div className="theme-title-showmap-container">
              <IconShow fill="#00ff00" width="20" height="20" />
              <p className="theme-title-showmap">Se kort</p>
            </div>
          )}
        </button>
        <button className="theme-btn" onClick={nextMapTheme}>
          <FaChevronRight className="theme-btn-icon" />
        </button>
      </div>
      {/* <button
        style={{ all: "unset", color: "#efefef", fontSize: "0.9rem" }}
        onClick={instaWin}
      >
        Insta win
      </button> */}
      <Navigation />
    </>
  );
};
export default PuzzleHard;
