export default function IconParagliding({ height, width, fill, blur }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="icon"
      width={width ? width : height ? height : 20}
      height={height ? height : width ? width : 20}
      viewBox="0 0 50 50"
    >
      <defs>
        <filter
          id="Shape"
          x="0"
          y="0"
          width="50.298"
          height="50.298"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor={blur && "#00fe78"} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <path
        id="rocket_launch_FILL0_wght400_GRAD0_opsz48"
        d="M24 33.95q-1.65 0-2.8-1.15T20.05 30q0-1.65 1.15-2.8t2.8-1.15q1.65 0 2.8 1.15t1.15 2.8q0 1.65-1.15 2.8T24 33.95ZM18.2 48.3V41q-3.65-1.35-5.5-4.3-1.85-2.95-2.35-6.55-.1-.8.425-1.375T12.2 28.2q.65 0 1.15.475.5.475.55 1.125.45 4.3 3.025 6.35Q19.5 38.2 24 38.2t7.075-2.05Q33.65 34.1 34.1 29.8q.05-.65.575-1.125.525-.475 1.175-.475.85 0 1.4.575.55.575.45 1.375-.45 3.65-2.35 6.575Q33.45 39.65 29.8 41v7.3Zm-8-23.1-4.5-8q-.55.3-1.1.45-.55.15-1.1.15-.85 0-1.425-.525Q1.5 16.75 1.5 15.8V7.75q0-3.5 6.7-5.875T24-.5q9.1 0 15.8 2.375 6.7 2.375 6.7 5.875v8.05q0 .95-.575 1.475-.575.525-1.425.525-.55 0-1.075-.15-.525-.15-1.125-.45l-4.5 8h-3.6l-3.15-11.4q-1.8-.25-3.55-.375-1.75-.125-3.5-.125t-3.5.125q-1.75.125-3.5.375l-3.2 11.4Zm1.75-2.55 1.9-8.35q-1.6.35-2.875.775Q9.7 15.5 8.3 16.1Zm24.1.05 3.65-6.6q-1.4-.6-2.675-1.025-1.275-.425-2.875-.775ZM5.5 12.95q3.8-1.5 8.65-2.55Q19 9.35 24 9.35t9.85 1.05q4.85 1.05 8.65 2.55v-4.9q-.9-1.15-5.65-2.85Q32.1 3.5 24 3.5T11.15 5.2Q6.4 6.9 5.5 8.05ZM24 3.5Z"
        // transform="translate(-3.75 -4.09)"
        fill={fill ? fill : "#6C7270"}
      />
    </svg>
  );
}
