//import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import SeptimaLogoNeon1 from "../components/icons/SeptimaLogoNeon1";
import SeptimaLogoNeon2 from "../components/icons/SeptimaLogoNeon2";
import SeptimaLogoNeon3 from "../components/icons/SeptimaLogoNeon3";

const Spinner = () => {
  const style = {
    WebkitFilter: "drop-shadow(0px 0px 6px rgb(0, 254, 131))",
    filter: "drop-shadow(0px 0px 6px rgb(0, 254, 131))",
  };

  return (
    <div className="spinner">
      <motion.div
        className="splash-grid"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ delay: 1, duration: 1 }}
        // onAnimationComplete={() => {}}
      >
        <motion.div
          className="splash-grid-tile"
          // style={style}
          initial={{ x: 76 }}
          transition={{ delay: 1.3, duration: 0.5 }}
        >
          <SeptimaLogoNeon2 width="70" height="70" blur />
        </motion.div>
        <motion.div
          className="splash-grid-tile"
          style={style}
          initial={{ y: 76 }}
          animate={{ x: -76 }}
          transition={{ delay: 0.8, duration: 0.5 }}
        >
          <SeptimaLogoNeon3 width="70" height="70" blur />
        </motion.div>
        <motion.div
          className="splash-grid-tile"
          style={style}
          animate={{ y: -76 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <SeptimaLogoNeon1 width="70" height="70" blur />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Spinner;
